import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { SwiperSlide } from "swiper/react"
import Carousel from "@components/carousel"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Stack from "react-bootstrap/Stack"
import ButtonLink from "@components/ButtonLink"
import GoogleReviews from "@components/googleReviews"
import ModuleTitle from "@components/modules/Title"
import ModuleText from "@components/modules/Text"
import { StaticImage } from "gatsby-plugin-image"
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform"
import SearchCta from "@components/modules/SearchCta/homeSearchCta"
import { contentItemStagger, contentItem } from "@components/utils/animations"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import "./carousel.scss"

const sliderConfig = {
  slidesPerView: 1,
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
  autoplay: {
    delay: 5000,
  },
}

const BannerCarousel = ({
  imagetransforms,
  pageId,
  items,
  CountryFlagStatus,
  page,
}) => {
  // GGFX
  let processedImages = JSON.stringify({})
  if (imagetransforms?.BannerImage_Transforms) {
    processedImages = imagetransforms.BannerImage_Transforms
  }
  // const [renderComponent, setRenderComponent] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("mousemove", () => {
  //     if (renderComponent === false) {
  //       setRenderComponent(true)
  //     }
  //     })
  //     window.addEventListener("touchmove", () => {
  //         if (renderComponent === false) {
  //         setRenderComponent(true)
  //         }
  //     })
  //     window.addEventListener("keypress", () => {
  //         if (renderComponent === false) {
  //         setRenderComponent(true)
  //         }
  //     })
  // }, [])
  return (
    // <InView {...inViewOptions}>
    //     {({ ref, inView }) => (
    <section
      className={`section section-primary ${page}`}
      // ref={ref}
      // initial="hidden"
      // animate={inView ? "visible" : "hidden"}
      // variants={containerVariants}
    >
      <Carousel
        className={`featured-carousel ${page}`}
        {...sliderConfig}
        initialSlide={Math.floor(Math.random() * items.length)}
      >
        {items?.map((item, i) => {
          let swiperLazy = i != 0 ? "swiperLazy" : ""
          let lazyLoad = false
          const buttons = [
            {
              manualLink: item.manualLink,
              page: item.page,
              btnClass: item.btnClass,
              label: item.label,
              icon: item.icon,
              id: item.id,
            },
            {
              manualLink: item.manualLink2,
              page: item.page,
              btnClass: item.btnClass,
              label: item.label2,
              icon: item.icon,
              id: item.id,
            },
          ]
          var as = i == 0 ? "div" : "p"
          //console.log("title",as)
          return item.Show_Slide_On ? (
            <SwiperSlide key={i}>
              <div
                className="featured-carousel-item"
                variants={contentItemStagger}
              >
                {item?.carouselImage?.url ? (
                  <>
                    {page == "Home" ? (
                      <GatsbyImage
                        image={getImage(item.carouselImage.url_sharp)}
                        className={`featured-carousel-item-img ${swiperLazy}`}
                        id={pageId}
                        alt="img"
                        loading={swiperLazy ? "lazy" : "eager"}
                      />
                    ) : (
                      <ImageTransform
                        imagesources={item.carouselImage.url}
                        renderer="srcSet"
                        imagename="pages.BannerImage.imageCarousel"
                        attr={{
                          alt: "",
                          className: "featured-carousel-item-img " + swiperLazy,
                        }}
                        imagetransformresult={processedImages}
                        id={pageId}
                        lazyLoading={lazyLoad}
                      />
                    )}
                  </>
                ) : (
                  <StaticImage
                    src="../../../images/no-image.png"
                    width={1400}
                    height={540}
                    formats={["auto", "webp"]}
                    alt=""
                    className={"featured-carousel-item-img " + swiperLazy}
                    lazyLoading={lazyLoad}
                  />
                )}

                <Container fluid="lg">
                  <Row>
                    <Col xs={12}>
                      <Stack
                        gap={32}
                        className={`${
                          CountryFlagStatus
                            ? "pt-130 country_page_carosul"
                            : "pt-90"
                        } gap-32 pb-80 py-md-64 py-lg-160  justify-content-center featured-carousel-item__content`}
                      >
                        <div className="banner-content">
                          <div className="left">
                            {item?.ItemTitle && (
                              <div variants={contentItem}>
                                <ModuleTitle
                                  as={as}
                                  title={item.ItemTitle}
                                  isBannerCarouselPage={i === 0 ? true : false}
                                  className="h6 featured-carousel-item__title"
                                />
                              </div>
                            )}
                          </div>
                          <div className="right">
                            {item?.ItemText && (
                              <div variants={contentItem}>
                                <ModuleText
                                  text={item.ItemText}
                                  className="featured-carousel-item__text"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        {buttons[0].label && (
                          <div
                            className="d-flex flex-column gap-20 gap-md-40 flex-md-row featured-carousel-item__buttons"
                            variants={contentItem}
                          >
                            {buttons.map(button => {
                              const addedBtnClass = {
                                ...button,
                                btnClass: "btn-secondary",
                              }
                              return (
                                <>
                                  <ButtonLink
                                    func={
                                      button?.label === "Register your interest"
                                        ? "OffPlanModal"
                                        : ""
                                    }
                                    {...addedBtnClass}
                                    key={button?.id}
                                  />
                                </>
                              )
                            })}
                          </div>
                        )}
                        {item?.showGoogleRating && (
                          <div variants={contentItem}>
                            <GoogleReviews
                              GoogleReview_Content={item.GoogleReview_Content}
                            />
                          </div>
                        )}
                      </Stack>
                    </Col>
                  </Row>
                </Container>
              </div>
            </SwiperSlide>
          ) : null
        })}
      </Carousel>
      <SearchCta {...module} />
    </section>
    //     )}
    // </InView>
  )
}

export default BannerCarousel

export const imageBannerCarouselFragment = graphql`
  fragment ImageBannerCarouselFragment on GLSTRAPI_ComponentModulesBannerCarousel {
    id
    moduleType
    Items {
      id
      ItemText
      ItemTitle
      VideoUrl
      showGoogleRating
      hideBreadcrumbs
      GoogleReview_Content
      manualLink
      btnClass
      label
      label2
      manualLink2
      icon
      Show_Slide_On
      page {
        Slug
        id
        Parent {
          Slug
        }
        Parent2 {
          Slug
        }
        Parent3 {
          Slug
        }
      }
      carouselImage: Image {
        url
        url_sharp {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP], quality: 50, breakpoints: [380, 991, 1500])
          }
        }
      }
    }
  }
`
