import React from 'react';
import { graphql } from 'gatsby';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from 'react-bootstrap/Stack';
import ButtonLink from '@components/ButtonLink';
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import Breadcrumbs from '@components/breadcrumbs';
import { StaticImage } from "gatsby-plugin-image";
import { cleanP, onlyNumbers, uaenumberformat } from '@components/utils/CleanedHtml';

import { fadeInFromTop, fadeInFromBottom } from '@components/utils/animations';

import "./image.scss";


const FloatBannerImage = ({ imagetransforms, pageId, className, breadcrumbs, breadcrumbsClass, office }) => {
    const {
        Address,
        Enquire,
        PhoneNumber,
        Title,
        WhatsappNumber,
        BannerImage
    } = office;

    // GGFX
    let processedImages = JSON.stringify({});
    if (imagetransforms?.BannerImage?.floatImage_Transforms) {
        processedImages = imagetransforms.BannerImage.floatImage_Transforms;
    }

    return (
        // <InView {...inViewOptions}>
        //     {({ ref, inView }) => (
                <section
                    className={`section image-banner${className ? ' ' + className : ''}`}
                    // ref={ref}
                    // initial="hidden"
                    // animate={inView ? "visible" : "hidden"}
                    // variants={containerVariants}
                >
                    <StaticImage
                        src="../../../images/pattern.jpg"
                        quality={50}
                        formats={["auto", "webp"]}
                        alt="Background Image - Exclusive Links"
                        className="pattern-img"
                    />
                    <Container fluid="lg">
                        <Row>
                            <Col xs={12} lg={5}>
                                <Stack className="gap-16 gap-md-24 gap-lg-16 image-banner__content" variants={fadeInFromTop}>
                                    {!BannerImage[0]?.hideBreadcrumbs &&
                                        <Breadcrumbs
                                            breadcrumbs={breadcrumbs}
                                            breadcrumbsClass={breadcrumbsClass}
                                        />
                                    }
                                    {Title &&
                                        <ModuleTitle
                                            title={Title}
                                            className="h6 text-white image-banner__title"
                                            as="h1"
                                        />
                                    }
                                    {Address &&
                                        <ModuleText
                                            text={Address}
                                            className="image-banner__text d-block"
                                        />
                                    }
                                    <Stack direction="vertical" className="gap-16 gap-md-24 flex-md-row mt-8 mt-lg-24 flex-wrap image-banner__btns">
                                        {WhatsappNumber &&
                                            <ButtonLink manualLink={`https://wa.me/${onlyNumbers(WhatsappNumber)}`} label={uaenumberformat(WhatsappNumber)} icon="whatsAppIcon" variant="whatsapp" />
                                        }
                                        {PhoneNumber &&
                                            <ButtonLink manualLink={`tel:${uaenumberformat(PhoneNumber)}`} label={uaenumberformat(PhoneNumber)} icon="phoneIcon" variant="secondary" />
                                        }
                                    </Stack>
                                    {Enquire &&
                                        <div className="mt-8 mt-lg-24">
                                            <ButtonLink manualLink={`#contactUsForm`} label={Enquire} icon="emailIcon" variant="link-secondary" btnClass="align-self-start" />
                                        </div>
                                    }
                                </Stack>
                            </Col>
                            <Col xs={12} lg={{ span: 5, offset: 2}} className="image-banner__wrapper__image">
                                <div className="image-banner__wrapper__image__div" variants={fadeInFromBottom}>
                                    {BannerImage[0]?.floatImage?.url ?
                                        // <ImageTransform
                                        //     imagesources={BannerImage[0].floatImage.url}
                                        //     renderer="srcSet"
                                        //     imagename="offices.Image.floatImage"
                                        //     attr={{ alt: `${BannerImage[0]?.Image?.alternativeText ? BannerImage[0]?.Image?.alternativeText : cleanP(Title)}`, className: "image-banner__image" }}
                                        //     imagetransformresult={processedImages}
                                        //     id={pageId}
                                        // />
                                        <img
                                        src={BannerImage[0].floatImage.url}
                                        // quality={100}
                                        // formats={["auto", "webp"]}
                                        alt={`${BannerImage[0]?.Image?.alternativeText ? BannerImage[0]?.Image?.alternativeText + " - Exclusive Links" : cleanP(Title) + " - Exclusive Links"}`}
                                        className="image-banner__image"
                                    />
                                    :
                                        <StaticImage
                                            src="../../../images/no-image.png"
                                            width={560}
                                            height={600}
                                            formats={["auto", "webp"]}
                                            alt=""
                                            className="image-banner__image"
                                        />
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
        //     )}
        // </InView>
    )
}

export default FloatBannerImage

export const floatBannerFragment = graphql`
    fragment FloatBannerFragment on GLSTRAPI_ComponentModulesBannerImage {
        id
        ItemText
        ItemTitle
        showGoogleRating
        hideBreadcrumbs
        floatImage: Image {
            alternativeText
            url
        }
        Buttons {
            id
            manualLink
            btnClass
            label
            icon
            func
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
    }
`