import React from "react";
import BannerImage from "@components/modules/Banner";
import BannerVideoImage from "@components/modules/Banner/videoImage";
import BannerCarousel from "@components/modules/Banner/carousel";
import BannerFloatImage from "@components/modules/Banner/floatImage";
import ImageBtnSlider from "@components/modules/Banner/imageBtnSlider";
import ImageBtn from "@components/modules/Banner/imageBtn";
import Terms from "@components/modules/Banner/terms";
import ProjectCarousel from "@components/modules/Banner/projectCarousel";

const BannerRender = ({ page, isGuide, ...rest }) => {
        const showVacationReviews = (page?.Slug && page.Slug === 'vacation-homes-in-dubai' ? true :false);
        switch(page?.HeaderTemplate) {
        case "ImageCarousel":
            return <BannerCarousel items={page?.BannerImage[0]?.Items} imagetransforms={page?.imagetransforms} pageId={page?.id} page={page?.Pagename} {...rest} />;
        case "ProjectCarousel":
            return <ProjectCarousel items={page?.BannerImage[0]?.Items} imagetransforms={page?.imagetransforms} pageId={page?.id} {...rest} />;
        case "VideoImage":
            return <BannerVideoImage items={page?.BannerImage[0]} imagetransforms={page?.imagetransforms} pageId={page?.id} {...rest} />;
        case "Default":
            return <BannerImage items={page?.BannerImage[0]} imagetransforms={page?.imagetransforms} pageId={page?.id} {...rest} />;
        case "FloatImage":
            return <BannerFloatImage items={page?.BannerImage[0]} imagetransforms={page?.imagetransforms} pageId={page?.id} {...rest} />;
        case "ImageBtnSlider":
            return <ImageBtnSlider isGuide={isGuide} items={page?.BannerImage[0]} imagetransforms={page?.imagetransforms} pageId={page?.id} bannerCtaBtns={page?.BannerImage[0]?.BannerCTAButtons} showVacationReviews={showVacationReviews} {...rest} />;
        case "ImageBtn":
            return <ImageBtn items={page?.BannerImage[0]} imagetransforms={page?.imagetransforms} pageId={page?.id} {...rest} />;
        case "Terms":
            return <Terms items={page?.BannerImage[0]} {...rest} />;
        default:
            return <BannerImage items={page?.BannerImage[0]} imagetransforms={page?.imagetransforms} pageId={page?.id} {...rest} />;
    }
}

export default BannerRender