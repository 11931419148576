import React from 'react';
import { graphql } from 'gatsby'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from 'react-bootstrap/Stack';
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import Breadcrumbs from '@components/breadcrumbs';
import ButtonLink from '@components/ButtonLink';

import { contentItemStagger, contentItem } from '@components/utils/animations';

import "./terms.scss";

const BannerTerms = ({ items, className, breadcrumbs, breadcrumbsClass }) => {
    const {
        ItemText,
        ItemTitle,
        hideBreadcrumbs,
        Buttons
    } = items

    return (
        
                <section
                    className={`section pt-40 pt-md-65 pt-lg-80${className ? ' ' + className : ''}`}
                    // ref={ref}
                    // initial="hidden"
                    // animate={inView ? "visible" : "hidden"}
                    // variants={containerVariants}
                >
                    <div
                        className="container-fullsize overlay"
                        variants={contentItemStagger}
                    >
                        <Container fluid="lg">
                            <Row>
                                <Col xs={12}>
                                    <Stack className="gap-16 gap-md-24 gap-lg-16 image-banner-terms__content">
                                        {!hideBreadcrumbs &&
                                            <div variants={contentItem}>
                                                <Breadcrumbs
                                                    breadcrumbs={breadcrumbs}
                                                    breadcrumbsClass={`section-breadcrumb-dark ${breadcrumbsClass}`}
                                                />
                                            </div>
                                        }
                                        {ItemTitle &&
                                            <div variants={contentItem}>
                                                <ModuleTitle
                                                    title={ItemTitle}
                                                    className="h6 image-banner__title"
                                                    as="h1"
                                                />
                                            </div>
                                        }
                                        {ItemText &&
                                            <div variants={contentItem}>
                                                <ModuleText
                                                    text={ItemText}
                                                    className="image-banner__text d-block"
                                                />
                                            </div>
                                        }
                                        {Buttons.length > 0 &&
                                            <Stack direction="vertical" className="gap-16 gap-md-24 flex-md-row mt-8 mt-lg-24 image-banner__btns" variants={contentItem}>
                                                {Buttons.map((button) => {
                                                    return(
                                                        <ButtonLink {...button} key={button?.id} />
                                                    )
                                                })}
                                            </Stack>
                                        }
                                        <div className="divider mt-16 mt-md-16 mt-lg-24" />
                                    </Stack>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
    )
}

export default BannerTerms

export const termsBannerFragment = graphql`
    fragment TermsBannerFragment on GLSTRAPI_ComponentModulesBannerImage {
        id
        ItemText
        ItemTitle
        hideBreadcrumbs
        moduleType
    }
`