import * as React from "react";
import { graphql } from "gatsby";
import PropertySearch from '@components/PropertySearch';
import PropertyWorth from "@components/PropertyWorth";
import Stack from "react-bootstrap/Stack";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import './style.scss';

const SearchCta = ({ moduleData }) => {
    return (
        <section
            className={`py-0 py-md-0 py-lg-0 section-primary search-cta home `} 
        >
            <Container fluid="lg">
                <Row>
                    <Col xs={12}>  <Stack gap={40} className="vstack search-cta__container position-relative z-index-5">
                        <PropertySearch />
                        <div className="search-cta__divider"></div>
                        <PropertyWorth />
                    </Stack>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}

export default SearchCta

export const propertySearchFragment = graphql`
    fragment PropertySearchFragment on GLSTRAPI_ComponentModulesSearchCta {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
    }
`