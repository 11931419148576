import React from 'react';
import { graphql } from 'gatsby';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from 'react-bootstrap/Stack';
import ButtonLink from '@components/ButtonLink';
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import Breadcrumbs from '@components/breadcrumbs';
import { StaticImage } from "gatsby-plugin-image";
import Lightbox from '@components/lightbox';
import { cleanP } from '@components/utils/CleanedHtml';

import { contentItemStagger, contentItem, imageZoom } from '@components/utils/animations';

import "./image.scss";


const ImageBtn = ({ imagetransforms, pageId, ggfxColl, className, breadcrumbs, breadcrumbsClass, items, afterTitle }) => {
    const {
        ItemTitle,
        ItemText,
        hideBreadcrumbs,
        Buttons,
        VideoUrl,
        imgBtnImage
    } = items;

    // GGFX
    let processedImages = JSON.stringify({});
    if (imagetransforms?.imageBtn_Transforms) {
        processedImages = imagetransforms?.imageBtn_Transforms;
    }

    const updatedMenuItems = breadcrumbs.map(item => {
        if (item.label) {
          // Update the label property
          item.label = item.label.replace("About Us", "About");
        }
        return item;
      });

    return (
        // <InView {...inViewOptions}>
        //     {({ ref, inView }) => (
                <section
                    className={`section image-banner image-banner-btn-slider${className ? ' ' + className : ''}`}
                    // ref={ref}
                    // initial="hidden"
                    // animate={inView ? "visible" : "hidden"}
                    // variants={containerVariants}
                >
                    <div
                        className="overlay position-relative"
                        variants={contentItemStagger}
                    >

                        <StaticImage
                            src="../../../images/pattern.jpg"
                            quality={50}
                            formats={["auto", "webp"]}
                            alt="Background Image - Exclusive Links"
                            className="pattern-img"
                        />

                        <Container fluid="lg">
                            <Row>
                                <Col xs={12}>
                                    <Stack className="gap-16 gap-md-24 gap-lg-16 image-banner__content">
                                        {!hideBreadcrumbs &&
                                            <div variants={contentItem}>
                                                <Breadcrumbs
                                                    breadcrumbs={updatedMenuItems}
                                                    breadcrumbsClass={breadcrumbsClass}
                                                />
                                            </div>
                                        }
                                        {ItemTitle &&
                                            <div variants={contentItem}>
                                                <ModuleTitle
                                                    title={ItemTitle}
                                                    className="h6 text-white image-banner__title"
                                                    as="h1"
                                                />
                                            </div>
                                        }
                                        {ItemText &&
                                            <div variants={contentItem}>
                                                <ModuleText
                                                    text={ItemText}
                                                    className="image-banner__text d-block"
                                                />
                                            </div>
                                        }
                                        {Buttons.length > 0 &&
                                            <Stack direction="vertical" className="gap-16 gap-md-24 flex-md-row mt-8 mt-lg-24 image-banner__btns" variants={contentItem}>
                                                {Buttons.map((button) => {
                                                    const addedBtnClass = {...button, btnClass: "btn-secondary"}
                                                    return(
                                                        <ButtonLink {...addedBtnClass} key={button?.id} />
                                                    )
                                                })}
                                            </Stack>
                                        }
                                        {afterTitle &&
                                            <div className="image-banner__after-title mt-md-16">{afterTitle}</div>
                                        }
                                    </Stack>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* {JSON.stringify(items)}-
                    {imgBtnImage?.url} */}
                    {VideoUrl && 
                        <Lightbox hideVideoIcon={VideoUrl === "#"? true : false} videoUrl={[`${VideoUrl}`]} lightboxType="youtube" className="image-banner__lightbox">
                            <div className="overflow-hidden">
                                <div variants={imageZoom}>
                                    {imgBtnImage?.url ?
                                        // <ImageTransform
                                        //     imagesources={imageBtn?.url}
                                        //     renderer="srcSet"
                                        //     imagename={ggfxColl ? `${ggfxColl}.BannerImage.imageBtn` : "pages.BannerImage.imageBtn"}
                                        //     attr={{ alt: `${cleanP(ItemTitle)} - Exclusivelinks`, class: "image-banner__image" }}
                                        //     imagetransformresult={processedImages}
                                        //     id={pageId}
                                        // />
                                        <img
                                        src={imgBtnImage?.url}
                                        // quality={100}
                                        // formats={["auto", "webp"]}
                                        alt={`${ItemTitle ? `${cleanP(ItemTitle)} - Exclusive Links` : "Image"}`}
                                        className="image-banner__image"
                                    />
                                    :
                                        <StaticImage
                                            src="../../../images/no-image.png"
                                            width={1400}
                                            height={540}
                                            formats={["auto", "webp"]}
                                            alt=""
                                            className="image-banner__image"
                                        />
                                    }
                                </div>
                            </div>
                        </Lightbox>
                    }
                    {!VideoUrl &&
                        <div className="overflow-hidden">
                            <div variants={imageZoom}>
                                {imgBtnImage?.url ?
                                    // <ImageTransform
                                    //     imagesources={imageBtn?.url}
                                    //     renderer="srcSet"
                                    //     imagename="pages.BannerImage.imageBtn"
                                    //     attr={{ alt: `${cleanP(ItemTitle)} - Exclusivelinks`, class: "image-banner__image" }}
                                    //     imagetransformresult={processedImages}
                                    //     id={pageId}
                                    // />
                                    <img src={imgBtnImage?.url} alt={`${cleanP(ItemTitle)} - Exclusive Links`} className={"image-banner__image"} />

                                :
                                    <StaticImage
                                        src="../../../images/no-image.png"
                                        width={1400}
                                        height={540}
                                        formats={["auto", "webp"]}
                                        alt=""
                                        className="image-banner__image"
                                    />
                                }
                            </div>
                        </div>
                    }
                    
                </section>
        //     )}
        // </InView>
    )
}

export default ImageBtn

export const imgBtnNormalFragment = graphql`
    fragment ImgBtnNormalFragment on GLSTRAPI_ComponentModulesBannerImage {
        id
        ItemText
        ItemTitle
        showGoogleRating
        hideBreadcrumbs
        imgBtnImage: Image {
            alternativeText
            url
        }
        Buttons {
            id
            manualLink
            btnClass
            label
            icon
            func
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
    }
`