import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { SwiperSlide } from "swiper/react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Stack from "react-bootstrap/Stack"
import ButtonLink from "@components/ButtonLink"
import ModuleTitle from "@components/modules/Title"
import ModuleText from "@components/modules/Text"
import Breadcrumbs from "@components/breadcrumbs"
import { StaticImage } from "gatsby-plugin-image"
import Lightbox from "@components/lightbox"
import Carousel from "@components/carousel"
import { cleanP } from "@components/utils/CleanedHtml"
import { StarberryIcons } from "@components/icons/index"

import Image from "@components/GatsbyImage/gatsbyImage"
import TotalReviewsCount from "@components/ReviewsComponent/TotalReviewsCount"

import "./image.scss"

const ImageBtnSlider = ({
  imagetransforms,
  pageId,
  ggfxColl,
  className,
  breadcrumbs,
  breadcrumbsClass,
  items,
  afterTitle,
  onImage,
  isGuide,
  bannerCtaBtns,
  showVacationReviews,
}) => {
  const {
    ItemTitle,
    Button_Group_Text,
    ItemText,
    hideBreadcrumbs,
    Buttons,
    VideoUrl,
    imgBtnImage,
    BannerCta,
    BannerCtaBtn,
  } = items

  // GGFX
  let processedImages = JSON.stringify({})
  // const [renderComponent, setRenderComponent] = useState(false)

  // useEffect(() => {
  //   window.addEventListener("mousemove", () => {
  //     if (renderComponent === false) {
  //       setRenderComponent(true)
  //     }
  //   })
  //   window.addEventListener("touchmove", () => {
  //     if (renderComponent === false) {
  //       setRenderComponent(true)
  //     }
  //   })
  //   window.addEventListener("keypress", () => {
  //     if (renderComponent === false) {
  //       setRenderComponent(true)
  //     }
  //   })
  // }, [])

  if (imagetransforms?.imgBtnImage_Transforms) {
    processedImages = imagetransforms?.imgBtnImage_Transforms
  }

  const sliderConfig = {
    slidesPerView: "auto",
    spaceBetween: 32,
    pagination: false,
    breakpoints: {
      1200: {
        slidesPerView: "auto",
        spaceBetween: 40,
      },
    },
  }

  return (
    <section
      className={`section image-banner image-banner-btn-slider${
        className ? " " + className : ""
      }${isGuide ? " areaguide-banner " : ""}`}
    >
      {isGuide ? (
        <>
          <div className="overlay position-relative">
            <StaticImage
              src="../../../images/pattern.jpg"
              quality={50}
              formats={["auto", "webp"]}
              alt="Background Image - Exclusive Links"
              className="pattern-img"
            />

            <Container fluid="lg">
              <Row>
                <Col xs={12}>
                  <Stack className="gap-16 gap-md-24 gap-lg-16 image-banner__content">
                    {!hideBreadcrumbs && (
                      <div>
                        <Breadcrumbs
                          breadcrumbs={breadcrumbs}
                          breadcrumbsClass={breadcrumbsClass}
                        />
                      </div>
                    )}
                    {ItemTitle && (
                      <div>
                        <ModuleTitle
                          title={ItemTitle}
                          className="h6 text-white image-banner__title"
                          as="h1"
                        />
                      </div>
                    )}
                    {ItemText && (
                      <div>
                        <ModuleText
                          text={ItemText}
                          className="image-banner__text d-block"
                        />
                      </div>
                    )}
                    {afterTitle && <>{afterTitle}</>}
                    {!afterTitle && (
                      <>
                        {Buttons.length > 0 && (
                          <div
                            className={
                              Button_Group_Text
                                ? "mt-32 mt-md-40 mt-lg-56 d-flex"
                                : "mt-32 mt-md-40 mt-lg-56"
                            }
                          >
                            {Button_Group_Text && (
                              <div class="btn-group-txt">
                                {Button_Group_Text}
                              </div>
                            )}
                            <Carousel
                              className="image-banner-btn__carousel"
                              {...sliderConfig}
                            >
                              {Buttons.map((button, i) => {
                                let addedBtnClass = {
                                  ...button,
                                  variant: "link-secondary",
                                }
                                if (0 === i) {
                                  addedBtnClass = {
                                    ...button,
                                    variant: "link-secondary-underlined",
                                  }
                                }
                                return (
                                  <SwiperSlide key={button?.id}>
                                    <ButtonLink {...addedBtnClass} />
                                  </SwiperSlide>
                                )
                              })}
                            </Carousel>
                          </div>
                        )}
                      </>
                    )}
                  </Stack>
                </Col>
              </Row>
            </Container>
          </div>
          {VideoUrl && (
            <div className="position-relative">
              <Lightbox
                hideVideoIcon={VideoUrl === "#" ? true : false}
                videoUrl={[`${VideoUrl}`]}
                lightboxType="youtube"
                className="image-banner__lightbox overflow-hidden"
              >
                <div className="overflow-hidden">
                  <div>
                    {onImage.props.images?.length > 1 ? (
                      <div className="d-flex">
                        <img
                          src={onImage.props.images[0]?.url}
                          alt={`${cleanP(ItemTitle)} - Exclusivelinks`}
                          className={"image-banner__image first-img"}
                        />
                        <img
                          src={onImage.props.images[1]?.url}
                          alt={`${cleanP(ItemTitle)} - Exclusivelinks`}
                          className={"image-banner__image second-img"}
                        />
                      </div>
                    ) : imgBtnImage?.url || items?.Image?.url ? (
                      <img
                        src={
                          imgBtnImage?.url
                            ? imgBtnImage?.url
                            : items?.Image?.url
                        }
                        alt={`${cleanP(ItemTitle)} - Exclusivelinks `}
                        className={"image-banner__image width-100 "}
                      />
                    ) : (
                      <StaticImage
                        src="../../../images/no-image.png"
                        width={1400}
                        height={540}
                        formats={["auto", "webp"]}
                        alt=""
                        className="image-banner__image width-100 "
                      />
                    )}
                  </div>
                </div>
              </Lightbox>
              {onImage && <>{onImage}</>}
            </div>
          )}
          {!VideoUrl && (
            <div className="overflow-hidden">
              <div>
                {onImage.props.images?.length > 1 ? (
                  <div className="d-flex">
                    <img
                      src={onImage.props.images[0]?.url}
                      alt={`${cleanP(ItemTitle)} - Exclusivelinks`}
                      className={"image-banner__image first-img"}
                    />
                    <img
                      src={onImage.props.images[1]?.url}
                      alt={`${cleanP(ItemTitle)} - Exclusivelinks`}
                      className={"image-banner__image second-img"}
                    />
                  </div>
                ) : imgBtnImage?.url || items?.Image?.url ? (
                  <img
                    src={
                      imgBtnImage?.url ? imgBtnImage?.url : items?.Image?.url
                    }
                    alt={`${cleanP(ItemTitle)} - Exclusivelinks`}
                    className={"image-banner__image width-100"}
                  />
                ) : (
                  <StaticImage
                    src="../../../images/no-image.png"
                    width={1400}
                    height={540}
                    formats={["auto", "webp"]}
                    alt=""
                    className="image-banner__image width-100"
                  />
                )}
                {onImage && <>{onImage}</>}
              </div>
            </div>
          )}
          {BannerCta && (
            <div className="image-banner__cta__section">
              <Container fluid="lg">
                <Row>
                  <Col xs={12}>
                    <Stack className="gap-16 gap-lg-40 flex-lg-row text-center text-lg-start">
                      <div>{BannerCta}</div>
                      {BannerCtaBtn && (
                        <ButtonLink
                          variant="none"
                          {...BannerCtaBtn}
                          className="ms-lg-auto"
                          label={
                            <>
                              {BannerCtaBtn?.label}{" "}
                              <StarberryIcons
                                iconName="rightChevronIcon"
                                className="ms-22"
                              />
                            </>
                          }
                        />
                      )}
                    </Stack>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="overlay position-relative">
              <StaticImage
                src="../../../images/pattern.jpg"
                quality={50}
                formats={["auto", "webp"]}
                alt="Background Image - Exclusive Links"
                className="pattern-img"
              />
            <Container fluid="lg">
              <Row>
                <Col xs={12}>
                  <Stack className="gap-16 gap-md-24 gap-lg-16 image-banner__content">
                    {!hideBreadcrumbs && (
                      <div>
                        <Breadcrumbs
                          breadcrumbs={breadcrumbs}
                          breadcrumbsClass={breadcrumbsClass}
                        />
                      </div>
                    )}
                    <div
                      className={`image-banner__content-wrap ${
                        showVacationReviews &&
                        "image-banner__content__reviews-wrap"
                      }`}
                    >
                      {ItemTitle && (
                        <div className="image-banner__title-wrap">
                          <ModuleTitle
                            title={ItemTitle}
                            className="h6 text-white image-banner__title"
                            as="h1"
                          />
                        </div>
                      )}
                      {showVacationReviews && (
                        <div className="image-banner__reviews-wrap">
                          <TotalReviewsCount
                            department="vacationHomes"
                            linkReviews={true}
                            showStars={true}
                          />
                        </div>
                      )}
                      {ItemText && (
                        <div className="image-banner__text-wrap">
                          <ModuleText
                            text={ItemText}
                            className="image-banner__text d-block"
                          />
                        </div>
                      )}
                    </div>
                    {bannerCtaBtns?.length > 0 && (
                      <Stack
                        direction="vertical"
                        className="gap-16 gap-md-24 flex-md-row mt-8 mt-lg-24 image-banner__btns test1"
                      >
                        {bannerCtaBtns.map(button => {
                          const addedBtnClass = {
                            ...button,
                            btnClass: "btn-secondary",
                          }
                          return (
                            <ButtonLink {...addedBtnClass} key={button?.id} />
                          )
                        })}
                      </Stack>
                    )}
                    {afterTitle && <>{afterTitle}</>}
                    {!afterTitle && (
                      <>
                        {Buttons.length > 0 && (
                          <div
                            className={
                              Button_Group_Text
                                ? "mt-32 mt-md-40 mt-lg-56 d-flex"
                                : "mt-32 mt-md-40 mt-lg-56"
                            }
                          >
                            {Button_Group_Text && (
                              <div class="btn-group-txt">
                                {Button_Group_Text}
                              </div>
                            )}
                            <Carousel
                              className="image-banner-btn__carousel"
                              {...sliderConfig}
                            >
                              {Buttons.map((button, i) => {
                                let addedBtnClass = {
                                  ...button,
                                  variant: "link-secondary",
                                }
                                if (0 === i) {
                                  addedBtnClass = {
                                    ...button,
                                    variant: "link-secondary-underlined",
                                  }
                                }
                                return (
                                  <SwiperSlide key={button?.id}>
                                    <ButtonLink {...addedBtnClass} />
                                  </SwiperSlide>
                                )
                              })}
                            </Carousel>
                          </div>
                        )}
                      </>
                    )}
                  </Stack>
                </Col>
              </Row>
            </Container>
          </div>
          {VideoUrl && (
            <div className="position-relative">
              <Lightbox
                hideVideoIcon={VideoUrl === "#" ? true : false}
                videoUrl={[`${VideoUrl}`]}
                lightboxType="youtube"
                className="image-banner__lightbox overflow-hidden"
              >
                <div className="overflow-hidden">
                  <div>
                    <Image
                      g_image={imgBtnImage?.url_sharp}
                      alt={`${
                        ItemTitle
                          ? `${cleanP(ItemTitle)} - Exclusivelinks`
                          : "Image"
                      }`}
                      className={"image-banner__image width-100"}
                      s_width={1400}
                      s_height={540}
                      s_loading={"eager"}
                    />
                  </div>
                </div>
              </Lightbox>
              {onImage && <>{onImage}</>}
            </div>
          )}
          {!VideoUrl && (
            <div className="overflow-hidden">
              <div>
                  <Image
                    g_image={imgBtnImage?.url_sharp}
                    alt={`${
                      ItemTitle
                        ? `${cleanP(ItemTitle)} - Exclusivelinks`
                        : "Image"
                    }`}
                    className="image-banner__image width-100"
                    s_width={1400}
                    s_height={540}
                  />
                {onImage && <>{onImage}</>}
              </div>
            </div>
          )}
          {BannerCta && (
            <div className="image-banner__cta__section">
              <Container fluid="lg">
                <Row>
                  <Col xs={12}>
                    <Stack className="gap-16 gap-lg-40 flex-lg-row text-center text-lg-start">
                      <div>{BannerCta}</div>
                      {BannerCtaBtn && (
                        <ButtonLink
                          variant="none"
                          {...BannerCtaBtn}
                          className="ms-lg-auto"
                          label={
                            <>
                              {BannerCtaBtn?.label}{" "}
                              <StarberryIcons
                                iconName="rightChevronIcon"
                                className="ms-22"
                              />
                            </>
                          }
                        />
                      )}
                    </Stack>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </>
      )}
    </section>
  )
}

export default ImageBtnSlider

export const imgBtnFragment = graphql`
  fragment ImgBtnFragment on GLSTRAPI_ComponentModulesBannerImage {
    id
    ItemText
    ItemTitle
    showGoogleRating
    hideBreadcrumbs
    imgBtnImage: Image {
      alternativeText
      url
      url_sharp {
        childImageSharp {
          gatsbyImageData(width: 1800, formats: WEBP, quality: 90)
        }
      }
    }
    Button_Group_Text
    Buttons {
      id
      manualLink
      btnClass
      label
      icon
      func
      page {
        Slug
        id
        Parent {
          Slug
        }
        Parent2 {
          Slug
        }
        Parent3 {
          Slug
        }
      }
    }
    BannerCta
    BannerCtaBtn {
      id
      manualLink
      btnClass
      label
      icon
      func
      page {
        Slug
        id
        Parent {
          Slug
        }
        Parent2 {
          Slug
        }
        Parent3 {
          Slug
        }
      }
    }
  }
`
