import React from 'react';
import { graphql } from 'gatsby';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from 'react-bootstrap/Stack';
import ButtonLink from '@components/ButtonLink';
// import GoogleReviews from '@components/googleReviews';
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import Breadcrumbs from '@components/breadcrumbs';
import { StaticImage } from "gatsby-plugin-image";
import { cleanP } from '@components/utils/CleanedHtml';

import { contentItemStagger, contentItem } from '@components/utils/animations';

import "./image.scss";

const BannerCarousel = ({ imagetransforms, pageId, items, className, breadcrumbs, breadcrumbsClass, afterTitle }) => {
    const {
        ItemText,
        ItemTitle,
        // showGoogleRating,
        hideBreadcrumbs,
        // moduleType,
        // VideoUrl,
        imageBannerImage,
        Buttons
    } = items

    // GGFX
    let processedImages = JSON.stringify({});
    if (imagetransforms?.imageBannerImage_Transforms) {
        processedImages = imagetransforms.imageBannerImage_Transforms;
    }

    return (
        // <InView {...inViewOptions}>
        //     {({ ref, inView }) => (
                <section
                    className={`section image-banner${className ? ' ' + className : ''}`}
                    // ref={ref}
                    // initial="hidden"
                    // animate={inView ? "visible" : "hidden"}
                    // variants={containerVariants}
                >
                    <div
                        className="overlay position-relative"
                        variants={contentItemStagger}
                    >
                        {imageBannerImage?.url &&
                            // <ImageTransform
                            //     imagesources={imageBannerImage.url}
                            //     renderer="srcSet"
                            //     imagename="pages.BannerImage.imageBtn"
                            //     attr={{ alt: `${ItemTitle ? cleanP(ItemTitle) : ""}`, className: "image-banner-item-img" }}
                            //     imagetransformresult={processedImages}
                            //     id={pageId}
                            // />
                            <img
                                src={imageBannerImage.url}
                                // quality={100}
                                // formats={["auto", "webp"]}
                                alt={`${ItemTitle ? cleanP(ItemTitle) + " - Exclusive Links" : "Image"}`}
                                className="image-banner-item-img"
                            />
                        }
                        {!imageBannerImage?.url &&
                            <StaticImage
                                src="../../../images/pattern.jpg"
                                quality={50}
                                formats={["auto", "webp"]}
                                alt="Background Image - Exclusive Links"
                                className="pattern-img"
                            />
                        }
                        <Container fluid="lg">
                            <Row>
                                <Col xs={12}>
                                    <Stack className="gap-16 gap-md-24 gap-lg-16 image-banner__content">
                                        {!hideBreadcrumbs &&
                                            <div variants={contentItem}>
                                                <Breadcrumbs
                                                    breadcrumbs={breadcrumbs}
                                                    breadcrumbsClass={breadcrumbsClass}
                                                />
                                            </div>
                                        }
                                        {ItemTitle &&
                                            <div variants={contentItem}>
                                                <ModuleTitle
                                                    title={ItemTitle}
                                                    className="h6 text-white image-banner__title"
                                                    as="h1"
                                                />
                                            </div>
                                        }
                                        {ItemText &&
                                            <div variants={contentItem}>
                                                <ModuleText
                                                    text={ItemText}
                                                    className="image-banner__text d-block"
                                                />
                                            </div>
                                        }
                                        {afterTitle &&
                                            <>{afterTitle}</>
                                        }
                                        {!afterTitle &&
                                            <>
                                                {Buttons.length > 0 &&
                                                    <Stack direction="vertical" className="gap-16 gap-md-24 flex-md-row mt-8 mt-lg-24 image-banner__btns" variants={contentItem}>
                                                        {Buttons.map((button) => {
                                                            const addedBtnClass = {...button, btnClass: "btn-secondary"}
                                                            return(
                                                                <ButtonLink {...addedBtnClass} key={button?.id} />
                                                            )
                                                        })}
                                                    </Stack>
                                                }
                                            </>
                                        }
                                    </Stack>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
        //     )}
        // </InView>
    )
}

export default BannerCarousel

export const imageBannerFragment = graphql`
    fragment ImageBannerFragment on GLSTRAPI_ComponentModulesBannerImage {
        id
        ItemText
        ItemTitle
        showGoogleRating
        hideBreadcrumbs
        moduleType
        VideoUrl
        imageBannerImage: Image {
            url
        }
        Buttons {
            id
            manualLink
            btnClass
            label
            icon
            func
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
    }
`